import styled from 'styled-components'
import { WHITE } from '../../../styles/colors'

export const Loading = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  padding: 1rem;
  color: ${WHITE};
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.7);
`
