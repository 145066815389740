import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './components/pages/home/HomePage'
import InfoPage from './components/pages/info/InfoPage'
import { Grommet, ThemeContext } from 'grommet'
import { PRIMARY } from './styles/colors'
import ConsentPage from './components/pages/consent/ConsentPage'
import { ConsentContextProvider } from './contexts/consentContext'
import NoticePage from './components/pages/notice/NoticePage'
import QuotePage from './components/pages/quote/QuotePage'

function App() {
  return (
    <Grommet>
      <ThemeContext.Extend
        value={{
          global: { colors: { brand: PRIMARY } },
          button: {
            padding: { vertical: '0.8rem' },
            border: { radius: 2 },
          },
        }}
      >
        <Router>
          <Switch>
            <Route path="/notice/:uuid">
              <NoticePage />
            </Route>
            <Route path="/consent/:uuid">
              <ConsentContextProvider>
                <ConsentPage />
              </ConsentContextProvider>
            </Route>
            <Route path="/info/:uuid">
              <InfoPage />
            </Route>
            <Route path="/quote/:uuid">
              <QuotePage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </Router>
      </ThemeContext.Extend>
    </Grommet>
  )
}

export default App
