import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { PRIMARY, WHITE } from '../../styles/colors'

const HeaderStyled = styled.header`
  width: 100%;
  color: ${WHITE};
  padding: 1rem 0;
  font-weight: bold;
  text-align: center;
  background: ${PRIMARY};
`

const Header: FunctionComponent = ({ children }) => {
  return <HeaderStyled>{children}</HeaderStyled>
}

export default Header
