import React, { FormEvent, useCallback, useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import {
  AlertError,
  AlertInfo,
  AlertSuccess,
} from '../../shared/styled/AlertInfo'
import { Button } from 'grommet'
import DeficiencyCheckBoxList from './DeficiencyCheckBoxList'
import { Loading } from '../../shared/styled/Loading'
import { useFetch } from '../../../hooks/useFetch'
import { ENVIRONMENT } from '../../../config'
import PageContainer from '../../shared/PageContainer'

const calcTimeLeft = (expires: number) => {
  const now = Date.now()
  const minutesLeft = Math.floor((expires - now) / 60000)
  return `${minutesLeft} minutter`
}

const InfoPage = () => {
  const { uuid } = useParams()
  const [countDown, setCountDown] = useState<number>()
  const { response: info, error } = useFetch(
    `${ENVIRONMENT.API_URL}info/${uuid}`
  )
  const [hasExpired, setHasExpired] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<string>()
  const [updateStatus, setUpdateStatus] = useState<{
    message: string
    type: 'error' | 'success'
  }>()
  const [order, setOrder] = useState<string[]>([])

  const initCountDown = useCallback(() => {
    // @ts-ignore
    setHasExpired(Date.now() > info.expires)
    setTimeLeft(calcTimeLeft(info.expires))

    let handler = setInterval(() => {
      // @ts-ignore
      const now = Date.now()

      setHasExpired(now > info.expires)
      setTimeLeft(calcTimeLeft(info.expires))
    }, 10000)

    setCountDown(handler)
  }, [info])

  useEffect(() => {
    if (info && info.id) {
      initCountDown()
    }
  }, [info, initCountDown])

  useEffect(() => {
    return () => clearInterval(countDown)
  }, [countDown])

  const updateInfo = (e: FormEvent) => {
    e.preventDefault()

    fetch(`${ENVIRONMENT.API_URL}info/${uuid}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ order }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Det oppstod dessverre en feil.')
        }

        setUpdateStatus({
          message: 'Din bestilling er oppdatert!',
          type: 'success',
        })
      })
      .catch((error) => {
        setUpdateStatus({
          message: 'Det oppstod dessverre en feil.',
          type: 'error',
        })
      })
  }

  if (error) {
    return <AlertError>{error}</AlertError>
  }

  if (info && info.id) {
    return (
      <PageContainer title="Cars Software AS">
        <h1>Hei {info.cuname}</h1>
        {hasExpired && <AlertError>Dette tilbudet har utløpt.</AlertError>}
        {info.booked && <AlertSuccess>Takk for din bestilling!</AlertSuccess>}
        <p>
          Vi har utført en gratis ekstrasjekk av ditt kjøretøy, og oppdaget{' '}
          {info.result.length} mangler under denne kontrollen. Hvis du ønsker å
          utbedre disse manglene kan du enkelt merke av én eller flere i listen
          nedenfor og sende inn bestilling.
        </p>
        {!hasExpired && (
          <AlertInfo style={{ marginTop: '2rem', marginBottom: '1.5rem' }}>
            Tilbudet utløper om {timeLeft}. Du kan fremdeles legge til flere
            punkter i din bestilling.
          </AlertInfo>
        )}
        <DeficiencyCheckBoxList
          items={info.result}
          checked={info.order ?? []}
          onCheckedItemsUpdate={(items) => setOrder(items)}
        />
        {updateStatus && updateStatus.type === 'success' && (
          <AlertSuccess>{updateStatus.message}</AlertSuccess>
        )}
        {updateStatus && updateStatus.type === 'error' && (
          <AlertError>{updateStatus.message}</AlertError>
        )}
        <Button
          style={{ marginTop: '1.5rem' }}
          primary
          fill="horizontal"
          label="Send bestilling"
          disabled={hasExpired}
          onClick={updateInfo}
        />
      </PageContainer>
    )
  }

  return <Loading>Vennligst vent...</Loading>
}

export default withRouter(InfoPage)
