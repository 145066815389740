import React from 'react'
import styled from 'styled-components'
import { BLACK } from '../../../styles/colors'

const ButtonLink = styled.a`
  display: block;
  width: 100%;
  padding: 1rem;
  color: ${BLACK};
  font-weight: bold;
  text-align: center;
  border: 1px solid #cccccc;
  background: #f7f7f7;
  text-decoration: none;

  &:active {
    background: white;
  }
`

const HomePage = () => {
  return (
    <React.Fragment>
      <h1>Kontakt oss</h1>
      <ButtonLink href="tel:32242070">32 24 20 70</ButtonLink>
    </React.Fragment>
  )
}

export default HomePage
