import React, { useCallback, useEffect, useState } from 'react'
import { withRouter, useParams } from 'react-router-dom'
import { Button, CheckBox } from 'grommet'
import { Loading } from '../../shared/styled/Loading'
import { ENVIRONMENT } from '../../../config'
import PageContainer from '../../shared/PageContainer'
import {
  AlertError,
  AlertInfo,
  AlertSuccess,
} from '../../shared/styled/AlertInfo'
import Dialog from '../../shared/styled/Dialog'

const calcTimeLeft = (expires: number) => {
  const now = Date.now()
  const minutesLeft = Math.floor((expires - now) / 60000)
  return `${minutesLeft} minutter`
}

const QuotePage = () => {
  const { uuid } = useParams()
  const [quote, setQuote] = useState<any>()
  const [countDown, setCountDown] = useState<number>()
  const [hasExpired, setHasExpired] = useState<boolean>(false)
  const [timeLeft, setTimeLeft] = useState<string>()
  const [showTerms, setShowTerms] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    fetch(`${ENVIRONMENT.API_URL}quote/${uuid}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Det oppstod dessverre en feil.')
        }

        return response.json()
      })
      .then(setQuote)
      .catch((error) => {
        setError('Det oppstod dessverre en feil.')
      })
  }, [uuid])

  const initCountDown = useCallback(() => {
    // @ts-ignore
    setHasExpired(Date.now() > quote.expires)
    setTimeLeft(calcTimeLeft(quote.expires))

    let handler = setInterval(() => {
      // @ts-ignore
      const now = Date.now()

      setHasExpired(now > quote.expires)
      setTimeLeft(calcTimeLeft(quote.expires))
    }, 10000)

    setCountDown(handler)
  }, [quote])

  useEffect(() => {
    if (quote && quote.id) {
      initCountDown()
    }
  }, [quote, initCountDown])

  useEffect(() => {
    return () => clearInterval(countDown)
  }, [countDown])

  const confirm = () => {
    fetch(`${ENVIRONMENT.API_URL}quote/${uuid}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ status: 2 }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Det oppstod dessverre en feil.')
        }

        return response.json()
      })
      .then((data) => {
        setQuote(data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  if (error) {
    return <AlertError>{error}</AlertError>
  }

  if (showTerms) {
    return (
      <Dialog title="Vilkår" onClose={() => setShowTerms(false)}>
        <h2>1. Avtalen</h2>
        <p>
          Avtalen mellom kjøper og selger består av opplysningene selgeren gir
          om kjøpet i bestillingsløsningen (herunder blant annet opplysninger om
          varens art, mengde, kvalitet, andre egenskaper, pris og
          leveringsbetingelser), eventuell direkte korrespondanse mellom partene
          (for eksempel e-post) samt disse salgsbetingelsene. Ved motstrid
          mellom opplysningene selgeren har gitt om kjøpet i
          bestillingsløsningen i nettbutikken, direkte korrespondanse mellom
          partene og vilkårene i salgsbetingelsene, går direkte korrespondanse
          mellom partene og opplysningene gitt i bestillingsløsningen foran
          salgsbetingelsene, så fremt det ikke strider mot bindende lovgivning.
        </p>
        <h3>2. Priser</h3>
        <p>
          Prisene, som er oppgitt i bestillingsløsningen, inkluderer
          merverdiavgift.
        </p>
        <h3>3. Avtaleinngåelse</h3>
        <p>
          Avtalen er bindende for begge parter når kjøperens bestilling er
          mottatt av selgeren. En part er likevel ikke bundet av avtalen hvis
          det har forekommet skrive- eller tastefeil i tilbudet fra selgeren i
          bestillingsløsningen i mobilbutikken eller i kjøperens bestilling, og
          den annen part innså eller burde ha innsett at det forelå en slik
          feil.
        </p>
      </Dialog>
    )
  }

  if (quote && quote.id) {
    return (
      <PageContainer title="Cars Software AS">
        <h1>Hei {quote.cuname}</h1>
        {hasExpired && <AlertError>Dette tilbudet har utløpt.</AlertError>}
        {quote.booked && (
          <React.Fragment>
            <AlertSuccess>Takk fo r din bestilling!</AlertSuccess>
            <AlertInfo>
              Bestillingen ble sendt {new Date(quote.booked).toLocaleString()}
            </AlertInfo>
            <h3>Kopi av bestilling:</h3>
          </React.Fragment>
        )}
        <p>{quote.text}</p>
        <p>Reg. nr: {quote.regid}</p>
        <h3>Pris kr. {quote.amount} inkl. mva</h3>
        {!hasExpired && !quote.booked && (
          <React.Fragment>
            <AlertInfo>Tilbudet utløper om {timeLeft}</AlertInfo>
            <div style={{ marginTop: '32px' }}>
              <CheckBox
                label="Ja, jeg aksepterer tilbudet"
                checked={quote.status === 2}
                onChange={(e) => {
                  console.log('CHECKED')
                  console.log(e.target.checked)

                  if (e.target.checked) {
                    setQuote({ ...quote, status: 2 })
                  } else {
                    setQuote({ ...quote, status: 1 })
                  }
                }}
              />
            </div>
            <Button
              label="Les vilkår"
              secondary
              fill="horizontal"
              style={{ marginTop: '32px' }}
              onClick={() => setShowTerms(true)}
            />
            <Button
              label="Send bestilling"
              primary
              fill="horizontal"
              style={{ marginTop: '32px' }}
              onClick={confirm}
              disabled={quote.status !== 2}
            />
          </React.Fragment>
        )}
      </PageContainer>
    )
  }

  return <Loading>Vennligst vent...</Loading>
}

export default withRouter(QuotePage)
