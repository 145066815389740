import React, { FunctionComponent, useContext } from 'react'
import { List } from '../../shared/List'
import styled from 'styled-components'
import { GREY_LIGHT } from '../../../styles/colors'
import { ConsentContext } from '../../../contexts/consentContext'
import { CheckBox } from 'grommet'

const ListLabel = styled.div`
  display: block;
  margin-bottom: 1rem;
`

const ListSection = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${GREY_LIGHT};
`

const Wrapper = styled.div`
  margin-bottom: 1rem;
`

const ConsentCheckBoxList: FunctionComponent = () => {
  const { consentItems, setConsentItems } = useContext(ConsentContext)

  const updateConsent = (key: string, checked: boolean) => {
    setConsentItems({
      ...consentItems,
      [key]: checked,
    })
  }

  return (
    <List>
      <ListSection>
        <ListLabel>Markedsinfo</ListLabel>
        <Wrapper>
          <CheckBox
            checked={consentItems.market_info_sms}
            label="SMS"
            onChange={(e) => {
              const checked = e.target.checked
              updateConsent('market_info_sms', checked)
            }}
          />
        </Wrapper>
        <CheckBox
          checked={consentItems.market_info_mail}
          label="E-post"
          onChange={(e) => {
            const checked = e.target.checked
            updateConsent('market_info_mail', checked)
          }}
        />
      </ListSection>
      <ListSection>
        <ListLabel>Påminnelser</ListLabel>
        <Wrapper>
          <CheckBox
            checked={consentItems.reminder_sms}
            label="SMS"
            onChange={(e) => {
              const checked = e.target.checked
              updateConsent('reminder_sms', checked)
            }}
          />
        </Wrapper>
        <CheckBox
          checked={consentItems.reminder_mail}
          label="E-post"
          onChange={(e) => {
            const checked = e.target.checked
            updateConsent('reminder_mail', checked)
          }}
        />
      </ListSection>
      <ListSection>
        <ListLabel>Innkalling til periodisk kontroll og service</ListLabel>
        <Wrapper>
          <CheckBox
            checked={consentItems.service_sms}
            label="SMS"
            onChange={(e) => {
              const checked = e.target.checked
              updateConsent('service_sms', checked)
            }}
          />
        </Wrapper>
        <CheckBox
          checked={consentItems.service_mail}
          label="E-post"
          onChange={(e) => {
            const checked = e.target.checked
            updateConsent('service_mail', checked)
          }}
        />
      </ListSection>
    </List>
  )
}

export default ConsentCheckBoxList
