import React, { createContext, FunctionComponent, useState } from 'react'

declare global {
  type Consent = {
    market_info_sms: boolean
    market_info_mail: boolean
    reminder_sms: boolean
    reminder_mail: boolean
    service_sms: boolean
    service_mail: boolean
  }
}

const initialConsent: Consent = {
  market_info_sms: false,
  market_info_mail: false,
  reminder_sms: false,
  reminder_mail: false,
  service_sms: false,
  service_mail: false,
}

export const ConsentContext = createContext({
  consentItems: initialConsent,
  setConsentItems: (consent: Consent) => {},
})

export const ConsentContextProvider: FunctionComponent = ({ children }) => {
  const [consentItems, setConsentItems] = useState<Consent>(initialConsent)

  return (
    <ConsentContext.Provider
      value={{
        consentItems,
        setConsentItems,
      }}
    >
      {children}
    </ConsentContext.Provider>
  )
}
