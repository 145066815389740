import styled from 'styled-components'
import { DANGER, GREY_LIGHT, SUCCESS, WHITE } from '../../../styles/colors'

export const AlertInfo = styled.div`
  padding: 1rem;
  background: ${GREY_LIGHT};
`

export const AlertError = styled.div`
  padding: 1rem;
  color: ${WHITE};
  background: ${DANGER};
`

export const AlertSuccess = styled.div`
  padding: 1rem;
  color: ${WHITE};
  background: ${SUCCESS};
`
