declare global {
  type Deficiency = {
    key: string
    type: string
    text: string
  }
}

const deficiencyMap: { [name: string]: Deficiency } = {
  '10': {
    key: '10',
    type: 'Motorolje',
    text: 'Nivået på motoroljen er for lavt og det er behov for påfyll',
  },
  '11': {
    key: '11',
    type: 'Frostvæske nivå',
    text: 'Nivået på frostvæsken er lavt og det er behov for påfyll',
  },
  '12': {
    key: '12',
    type: 'Frostvæske frysepunkt',
    text: 'Frostvæske med feil frysepunkt bør utbedres',
  },
  '13': {
    key: '13',
    type: 'Bremsevæske',
    text: 'Nivået for bremsevæske er lavt og må etterfylles',
  },
  '14': {
    key: '14',
    type: 'Batteri',
    text: 'Batteriet er dårlig og bør byttes',
  },
  '15': { key: '15', type: 'Vindusvisker foran', text: '' },
  '16': { key: '16', type: 'Vindusvisker bak', text: '' },
  '17': { key: '17', type: 'Lyspærer foran', text: '' },
  '18': { key: '18', type: 'Lyspærer bak', text: '' },
  '19': { key: '19', type: 'Støtdempere foran', text: '' },
  '20': { key: '20', type: 'Støtdempere bak', text: '' },
  '21': { key: '21', type: 'Dekk foran', text: '' },
  '22': { key: '22', type: 'Dekk bak', text: '' },
  '23': { key: '23', type: 'Forstilling', text: '' },
  '24': { key: '24', type: 'Bakstilling', text: '' },
  '25': { key: '25', type: 'Bremseklosser foran', text: '' },
  '26': { key: '26', type: 'Bremseklosser bak', text: '' },
  '27': { key: '27', type: 'Eksosanlegg', text: '' },
  '28': { key: '28', type: 'Tetthet motor', text: '' },
  '29': { key: '29', type: 'Tetthet girkasse', text: '' },
  '30': { key: '30', type: 'Frontrute', text: '' },
}

export default deficiencyMap
