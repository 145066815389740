import React, { useContext, useEffect, useState } from 'react'
import PageContainer from '../../shared/PageContainer'
import { useParams } from 'react-router-dom'
import { useFetch } from '../../../hooks/useFetch'
import { Loading } from '../../shared/styled/Loading'
import { ENVIRONMENT } from '../../../config'
import { AlertError, AlertSuccess } from '../../shared/styled/AlertInfo'
import { Button } from 'grommet'
import ConsentCheckBoxList from './ConsentCheckBoxList'
import Dialog from '../../shared/styled/Dialog'
import { ConsentContext } from '../../../contexts/consentContext'
import styled from 'styled-components'

const Dl = styled.dl``

const Dt = styled.dt`
  font-weight: bold;
`

const Dd = styled.dd`
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
`

const initialConsent = {
  market_info_sms: false,
  market_info_mail: false,
  reminder_sms: false,
  reminder_mail: false,
  service_sms: false,
  service_mail: false,
}

const ConsentPage = () => {
  const { uuid } = useParams()
  const { consentItems, setConsentItems } = useContext(ConsentContext)
  const { response: consent, error } = useFetch(
    `${ENVIRONMENT.API_URL}consent/${uuid}`
  )
  const [updateStatus, setUpdateStatus] = useState<{
    message: string
    type: 'error' | 'success'
  }>()
  const [showDialog, setShowDialog] = useState<boolean>(false)

  const updateConsent = (data: Consent) => {
    fetch(`${ENVIRONMENT.API_URL}consent/${uuid}`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Det oppstod dessverre en feil.')
        }

        setUpdateStatus({ message: 'Ditt svar er oppdatert!', type: 'success' })
        return response.json()
      })
      .then((response) => {
        setConsentItems({
          market_info_sms: response.market_info_sms,
          market_info_mail: response.market_info_mail,
          reminder_sms: response.reminder_sms,
          reminder_mail: response.reminder_mail,
          service_sms: response.service_sms,
          service_mail: response.service_mail,
        })
      })
      .catch((error) => {
        setUpdateStatus({
          message: 'Det oppstod dessverre en feil.',
          type: 'error',
        })
      })
  }

  useEffect(() => {
    if (consent) {
      setConsentItems({
        market_info_sms: consent.market_info_sms,
        market_info_mail: consent.market_info_mail,
        reminder_sms: consent.reminder_sms,
        reminder_mail: consent.reminder_mail,
        service_sms: consent.service_sms,
        service_mail: consent.service_mail,
      })
    }
  }, [consent, setConsentItems])

  if (showDialog) {
    return (
      <Dialog title="Personopplysninger" onClose={() => setShowDialog(false)}>
        <h3>Behandling av personopplysninger</h3>
        <p>
          Dine personopplysninger vil bli behandlet innenfor rammen av reglene i
          Personopplysningsloven av 14. april 2000 nr. 31 og
          markedsføringsloven.
        </p>
        <h3>Behandlingens formål</h3>
        <p>
          Vi behandler personopplysninger i den grad det er nødvendig for å
          inngå og gjennomføre våre kundeavtaler, føre interne regnskaper og
          gjennomføre kundetilfredshetsundersøkelser. Vi behandler også
          personopplysninger for å kunne rette direkte markedsføring mot deg, i
          den utstrekning du har samtykket til. Personopplysningen ikke bli
          benyttet til andre formål enn det som er nevnt her, med mindre du har
          samtykket til det eller det er påbudt ved lov.
        </p>
        <h3>Innsyn, retting og sletting</h3>
        <p>
          Du har rett til å be om innsyn i dine personopplysninger. Du har også
          rett til å be om at opplysninger som er uriktige eller ufullstendige,
          blir rettet eller slettet. Når behandlingen er basert på samtykke vil
          personopplysningene bli slettet, dersom du trekker tilbake samtykket.
          Dette gjelder allikevel ikke dersom sletting er i strid med andre
          påbud som er pålagt oss ved lov.
        </p>
      </Dialog>
    )
  }

  if (error) {
    return <AlertError>{error}</AlertError>
  }

  if (consent) {
    return (
      <PageContainer title={consent.sename}>
        {consent.answered && (
          <React.Fragment>
            <h1>Takk for din tilbakemelding!</h1>
            <p>Med vennlig hilsen, {consent.sename}</p>
          </React.Fragment>
        )}
        {!consent.answered && (
          <React.Fragment>
            <h1>Hei {consent.cuname}</h1>
            <p>
              Nå som den nye loven om personvern har trådt i kraft, er vi
              forpliktet til å innhente samtykke til å behandle
              personopplysninger. Vi ber vennligst om at du svarer på om du
              ønsker informasjon fra oss i fremtiden.
            </p>
            <Button
              secondary
              fill="horizontal"
              label="Les mer om personvern"
              onClick={() => setShowDialog(true)}
            />
            <p>Vi har registrert følgende opplysninger om deg:</p>
            <Dl>
              <Dt>Navn</Dt>
              <Dd>{consent.cuname}</Dd>
              <Dt>Mobilnr.</Dt>
              <Dd>{consent.cumobile}</Dd>
            </Dl>
            <p>Jeg samtykker til å motta elektronisk:</p>
            <ConsentCheckBoxList />
            {updateStatus && updateStatus.type === 'success' && (
              <AlertSuccess>{updateStatus.message}</AlertSuccess>
            )}
            {updateStatus && updateStatus.type === 'error' && (
              <AlertError>{updateStatus.message}</AlertError>
            )}
            <Button
              style={{ marginTop: '1.5rem' }}
              primary
              fill="horizontal"
              label="Send svar"
              onClick={(e) => {
                e.preventDefault()
                updateConsent(consentItems)
              }}
            />
            <Button
              style={{ marginTop: '1.5rem' }}
              secondary
              fill="horizontal"
              label="Nei takk, jeg ønsker ikke å kontaktes"
              onClick={(e) => {
                e.preventDefault()
                setConsentItems(initialConsent)
                updateConsent(initialConsent)
              }}
            />
          </React.Fragment>
        )}
      </PageContainer>
    )
  }

  return <Loading>Vennligst vent...</Loading>
}

export default ConsentPage
