import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { PRIMARY, WHITE } from '../../../styles/colors'
import { Button } from 'grommet'

const StyledDialog = styled.div``

const DialogHeader = styled.div`
  display: flex;
  height: 66px;
  align-items: center;
  padding-left: 1rem;
  color: ${WHITE};
  background: ${PRIMARY};

  > h1 {
    flex: 1;
    margin: 0;
    font-size: 1.5rem;
  }

  > button {
    color: ${WHITE};
  }
`

const DialogBody = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  height: ${window.screen.availHeight - 66}px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`

type Props = {
  title: string
  onClose: () => void
}

const Dialog: FunctionComponent<Props> = ({ title, children, onClose }) => {
  window.scrollTo(0, 0)

  return (
    <StyledDialog>
      <DialogHeader>
        <h1>{title}</h1>
        <Button label="Lukk" onClick={onClose} />
      </DialogHeader>
      <DialogBody>{children}</DialogBody>
    </StyledDialog>
  )
}

export default Dialog
