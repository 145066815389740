import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { CheckBox } from 'grommet'

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const ListItemStyled = styled.li`
  padding: 1rem;
`

type Props = {
  label: string
  subTitle?: string
  onChange: (checked: boolean) => void
  value: boolean
}

export const ListItem: FunctionComponent<Props> = ({ label, subTitle }) => {
  return (
    <ListItemStyled>
      <div>{label}</div>
      <small>{subTitle}</small>
    </ListItemStyled>
  )
}

export const CheckBoxListItem: FunctionComponent<Props> = ({
  label,
  subTitle,
  onChange,
  value,
}) => {
  const [checked, setChecked] = React.useState(value)

  const _onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    onChange(event.target.checked)
  }

  return (
    <ListItemStyled>
      <div>
        <CheckBox
          style={{ marginRight: '1rem' }}
          checked={checked}
          label={label}
          onChange={_onChange}
        />
      </div>

      <div style={{ marginLeft: '2.3rem' }}>
        <small>{subTitle}</small>
      </div>
    </ListItemStyled>
  )
}
