import React, { FunctionComponent, useState } from 'react'
import deficiencyMap from './DeficiencyMap'
import { CheckBoxListItem, List } from '../../shared/List'

type Props = {
  items: string[]
  onCheckedItemsUpdate: (items: string[]) => void
  checked: string[]
}

const DeficiencyCheckBoxList: FunctionComponent<Props> = ({
  items,
  checked,
  onCheckedItemsUpdate,
}) => {
  const [checkedItems, setCheckedItems] = useState<string[]>(checked)

  return (
    <List>
      {items.map((r, i) => {
        const isChecked = checkedItems.indexOf(r) !== -1

        return (
          <CheckBoxListItem
            value={isChecked}
            key={`cb-${i + 1}`}
            label={deficiencyMap[r].type}
            subTitle={deficiencyMap[r].text}
            onChange={(checked) => {
              if (isChecked) {
                const copy = [...checkedItems]
                copy.splice(checkedItems.indexOf(r), 1)
                setCheckedItems(copy)
                onCheckedItemsUpdate(copy)
              } else {
                setCheckedItems([...checkedItems, r])
                onCheckedItemsUpdate([...checkedItems, r])
              }
            }}
          />
        )
      })}
    </List>
  )
}

export default DeficiencyCheckBoxList
