import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import Header from './header'
import { GREY_DARK } from '../../styles/colors'

export const PageContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${window.screen.availHeight}px;
`

const MainContent = styled.div`
  padding: 1rem;
  flex: 1;

  > h1:first-of-type {
    margin-top: 0;
  }
`

const Footer = styled.footer`
  padding 1rem;
  text-align: center;
`

type Props = {
  title: string
}

const PageContainer: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <PageContainerStyled>
      <Header>{title}</Header>
      <MainContent>{children}</MainContent>
      <Footer>
        <small style={{ color: GREY_DARK }}>
          Levert av Cars Web Solutions AS
        </small>
      </Footer>
    </PageContainerStyled>
  )
}

export default PageContainer
