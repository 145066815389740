import React, { useEffect, useState } from 'react'
import PageContainer from '../../shared/PageContainer'
import { Box, Button, FormField, Image, TextInput } from 'grommet'
import image_1 from './images/image_1.jpg'
import image_2 from './images/image_2.jpg'
import { useParams } from 'react-router-dom'
import { ENVIRONMENT } from '../../../config'
import { Loading } from '../../shared/styled/Loading'
import { AlertError, AlertInfo } from '../../shared/styled/AlertInfo'

const images: any = {
  '1': image_1,
  '2': image_2,
}

type Notice = {
  id: string
  regid: string
  sename: string
  sephone: string
  cuname: string
  cumobile: string
  text: string
  pictureid: string
  is_sold: boolean
  created: string
  new_reg_id: string | null
}

const NoticePage = () => {
  const { uuid } = useParams()
  const [confirmVehicleIsSold, setConfirmVehicleIsSold] = useState<boolean>(
    false
  )
  const [notice, setNotice] = useState<Notice | undefined>(undefined)
  const [regno, setRegno] = useState<string>('')
  const [error, setError] = useState<string>('')

  useEffect(() => {
    fetch(`${ENVIRONMENT.API_URL}notice/${uuid}`)
      .then((response) => {
        if (!response.ok) {
          setError('Fant ikke tilbudet.')
        }

        return response.json()
      })
      .then(setNotice)
      .catch((error) => {
        setError('Det oppstod dessverre en feil.')
      })
  }, [uuid])

  const reportVehicleAsSold = async () => {
    setConfirmVehicleIsSold(false)
    setError('')

    try {
      const response = await fetch(`${ENVIRONMENT.API_URL}notice/${uuid}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({ new_reg_id: regno, is_sold: true }),
      })

      const data = await response.json()
      setNotice(data)
    } catch (error) {
      setError('Det oppstod dessverre en feil')
    }
  }

  const callWorkshop = () => {
    if (notice) {
      window.location.href = `tel:${notice.sename}`
    }
  }

  if (confirmVehicleIsSold) {
    return (
      <PageContainer title="Tilbud">
        <h1>Bekreft at bilen er solgt</h1>
        <p>Du kan også sende inn regnr. på din nye bil</p>
        <FormField htmlFor="regno" label="Regnr. på din nye bil">
          <TextInput
            id="regno"
            name="regno"
            value={regno}
            onChange={({ target }) => {
              setRegno(target.value.toUpperCase())
            }}
          />
        </FormField>
        <Button
          primary
          fill="horizontal"
          style={{ marginTop: '32px' }}
          label="Jeg bekrefter at bilen er solgt"
          onClick={reportVehicleAsSold}
        />
      </PageContainer>
    )
  }

  if (notice) {
    return (
      <PageContainer title="Tilbud">
        {error && <AlertError>{error}</AlertError>}
        <Box height="medium">
          <Image fit="contain" src={images[notice.pictureid]} />
        </Box>
        <h1>Hei {notice.cuname}</h1>
        <p>{notice.text}</p>
        {!notice.is_sold && (
          <Button
            primary
            fill="horizontal"
            style={{ marginTop: '32px' }}
            label="Jeg har solgt bilen"
            onClick={() => setConfirmVehicleIsSold(true)}
          />
        )}
        {notice.is_sold && (
          <AlertInfo>Du har gitt beskjed om at denne bilen er solgt.</AlertInfo>
        )}
        <Button
          secondary
          onClick={callWorkshop}
          fill="horizontal"
          style={{ marginTop: '32px' }}
          label={`Ring oss ${notice.sephone}`}
        />
      </PageContainer>
    )
  }

  return <Loading>Vennligst vent...</Loading>
}

export default NoticePage
