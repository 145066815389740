import { useEffect, useState } from 'react'

export const useFetch = (url: string) => {
  const [response, setResponse] = useState<any>()
  const [error, setError] = useState<string>()

  useEffect(() => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          setError('Det oppstod dessverre en feil.')
        }

        return response.json()
      })
      .then(setResponse)
      .catch((error) => {
        setError('Det oppstod dessverre en feil.')
      })
  }, [url])

  return { response, error }
}
